<template>
    <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
      <div class=" my-border">
        <v-card-title class="py-0 my-0 customlightgray">
          <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{correspondencesFiltered?correspondencesFiltered.length:'0'}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" v-if="$checkPermission('Correspondences.Create')" depressed @click="openAdd()">{{$t("newcorrespondence")}}</v-btn>
            </v-col>
             <!-- dialog add correspondence -->
        <v-dialog v-model="dialog" max-width="650px">
          <v-card class="customoffwhite">
            <v-card-title class="justify-center ma-0 customlightgray">
              <span class="font-weight-light">{{
                formTitle
              }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="container">
                <div class="row">
                   <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        clearable
                        v-model="cu_req.id"
                        :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                        :label="$t('requestid')"
                        :placeholder="$t('requestid')"
                        dense
                        outlined
                        required
                      ></v-autocomplete>
                  </v-col>
                   <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        clearable
                        v-model="activeCorrespondence.template_id"
                        :items="templates.map(template=>{return {value:template.id,text:template.name}})"
                        :label="$t('templatename')"
                        :placeholder="$t('templatename')"
                        dense
                        outlined
                        required
                      ></v-autocomplete>
                   </v-col>
                   <v-col cols="12" sm="12" md="12">
                    <v-menu  :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            v-model="activeCorrespondence.date_created" @input="(value) => activeCorrespondence.date_created = value " 
                            v-on="on"
                            outlined
                            dense
                            :label="$t('datecreated')"
                            :placeholder="$t('datecreated')"
                          ></v-text-field>
                        </template>
                        <v-date-picker dark :max="nowDate"
                          v-model="activeCorrespondence.date_created" @input="(value) => activeCorrespondence.date_created = value " 
                        ></v-date-picker>
                      </v-menu>
                  </v-col>
                  
                </div>
              </div>      
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="custom-color-accent-text" text @click="close">{{$t('cancel')}}</v-btn>
              <v-btn :disabled="isDisable" class="custom-color-accent-text" text @click="saveCorrespondence">{{$t('save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- //dialog add correspondence -->
          </v-row>
        </v-card-title>
         <!-- dialog advanced search -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    v-model="filters.request_id"
                    :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    v-model="filters.template_id"
                    :items="templates.map(template=>{return {value:template.id,text:template.name}})"
                    :label="$t('templatename')"
                    :placeholder="$t('templatename')"
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                   <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.date_created"
                        multiple
                        chips
                        dense
                        clearable
                        small-chips
                        :label="$t('createddaterange')"
                        :placeholder="$t('createddaterange')"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker range :max="nowDate" v-model="filters.date_created"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findCorrespondences">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /dialog advanced search -->
       <!-- Filters -->
        <v-divider  v-show="filterList.length>0"></v-divider>       
        <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
          <v-col class="pa-0 ma-0" cols="12">
            <v-scroll-x-transition group hide-on-leave>
            <v-tooltip v-for="(f, i) in filterList" :key="i" right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip  v-on='on' v-bind="attrs"
                  class="ma-1"
                  color="custom-color-accent"
                  close
                  small
                  close-icon="mdi-delete"
                  @click:close="removeFilter(f)" >
                    {{f}}
                  </v-chip>
                </template>
                <span>{{$t("removefilter")}}</span>
              </v-tooltip>
            </v-scroll-x-transition>
          </v-col>
        </v-row> 
        <!-- /Filters -->
        <v-divider></v-divider>
        <v-data-table :mobile-breakpoint="1081" 
          :headers="headers"
          :items="correspondencesFiltered"
          sort-by="id"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search"
          :loading="loading"
          :loading-text="$t('loading')"
          :footer-props="{
            'items-per-page-text': $t('rowsperpage'),
          }"
        >
          <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
          <template v-slot:[`item.date_created`]="{ item }">
            <span>{{ item.date_created | formatDate }}</span>
          </template> 
          <template v-slot:[`item.template_id`]="{ item }">
            <span>{{ getTemplateName(item.template_id) }}</span>
          </template>
          <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
            <v-row class="ma-0 pa-0 text-right">
              <v-col cols="12" class="ma-0 pa-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Correspondences.Update')">
                      <v-icon
                        @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t("edit")}}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Correspondences.Delete')">
                      <v-icon
                        @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t("delete")}}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Templates.Read')">
                      <v-icon
                        @click="downloadItem(item)">
                        mdi-cloud-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t("download")}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </div>

    <v-dialog v-model="previewDoc" max-width="800px">
      <v-card class="pa-5">
        <v-card-text v-html="docContent[0]" ref="previewDoc">
      {{docContent[0]}}
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>

          <v-btn class="custom-color-accent-text" text @click="previewDoc = false">{{$t('cancel')}}</v-btn>
          <v-btn class="custom-color-accent-text" text v-if="$checkPermission('Templates.Read')" @click="downloadDoc()">{{$t('Download Document')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </v-card>
</template>

<script>
import { mapActions,mapGetters, mapState} from "vuex";
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {
  },
   props: ["current_req","correspondencesFiltered"],
  data () {
    return {
      cu_req:{},
      nowDate: new moment().toISOString(),
      docContent:"",
      previewDoc:false,
      activeCorrespondence:{},
      search:"",
      filterList:[],
      editedIndex: -1,
      dialog_adv:false,
      dialog:false,
       headers: [
        {
          text: this.$t("requestid"),
          value: "request_id",
        },
        {
          text: this.$t("templatename"),
          value: "template_id",
        },
        {
          text: this.$t("datecreated"),
          value: "date_created",
        },
        { text: "", value: "action", sortable: false },
      ],
      filters: {
        request_id: [],
        template_id: [],
        date_created: [],
      },   
    }
  },
  async created () {
    Object.assign(this.filters, this.storeFilters);
    Object.assign(this.cu_req, this.current_req);
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
    await this.retriveTemplate();
  },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
          this.filterList = this.filterList.filter(el=> el != "");
      }, deep: true 
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('newcorrespondence'): this.$t('editcorrespondence');
    },
    tableTitle() {
      return this.$t("Correspondences");
    },
    ...mapGetters('template', {
      templates: 'getTemplatebyLanguage'
    }),
    ...mapState("request", {
      requests: "requests", user: "user"
    }),
    ...mapState("correspondence", {
     loading: "loading", storeFilters:"filter"
    }),

    isDisable() {
      return (
        !this.activeCorrespondence.template_id ||
        !this.activeCorrespondence.date_created
      );
    },
  },
  methods: {
     findCorrespondences(){
      this.close();
      this.filterCorrespondences(this.filters);
    },
    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterCorrespondences(this.filters);
    }, 
    getTemplateName(id){
      let name=  this.templates.filter(i => i.id === id ).map(j=>j.name)[0]
      return name;
    },
     async saveCorrespondence() {
      if (this.editedIndex == -1) {
        this.activeCorrespondence.request_id = this.cu_req.id;
        
        this.createCorrespondence(this.activeCorrespondence).then(()=>{
          let color="dark";
          let alert="itemAddedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        })
      } else {
        this.activeCorrespondence.request_id = this.cu_req.id;
        this.updateCorrespondence(this.activeCorrespondence).then(()=>{
          let color="dark";
          let alert="itemUpdatedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        })
      }
      this.close();  
    },
    close() {
      this.dialog = false;
      this.dialog_adv = false;
      this.editedIndex = -1;
      this.activeCorrespondence = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    openAdd() {
      this.dialog = true;
      this.editedIndex = -1;
      this.activeCorrespondence = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.correspondencesFiltered.indexOf(item);
      this.activeCorrespondence = item;
      this.cu_req.id = this.activeCorrespondence.request_id;
    },
    downloadItem(item) {
     this.docContent = this.templates.filter( t => t.id === item.template_id ).map( item => item.content );

      this.docContent[0] = this.docContent[0].replace(/\$(\w+.\w+)/g, (_, v) => {
        let string = v.split('.');
        if( string.length >= 1 ){
          if(string[0] === 'address'){
            return this.cu_req[string[0]][string[1]];
          }
          else if(string[0] == 'date'){ 
            return moment().format("YYYY-MM-DD");
          }
          else if(string[0] == 'user'){
            return this.user[string[1]];
          }else{
            return this.cu_req[string[0]];
          }
        }
        else{
          return this.cu_req[string[0]];
        }
      })
      this.previewDoc = true;
    },
    downloadDoc(){
        html2canvas( this.$refs.previewDoc, {scale:3}).then(canvas =>  {
          var w = this.$refs.previewDoc.offsetWidth;
          var h = this.$refs.previewDoc.offsetHeight;
            var imgData = canvas.toDataURL(
                'image/png');
            var doc = new jsPDF('P', 'mm');
            doc.addImage(imgData, 'JPEG', 10, 10, (w+20)*0.2645833333, (h+20)*0.2645833333);
            doc.save('sample-file.pdf');
        });
    },
    deleteItem(item) {
     if (confirm(this.$t("Are you sure you want to delete this item?")) &&  item ) {
        this.deleteCorrespondence(item).then(()=>{
          this.retrieveCorrespondences();
        });
      }
    },
    ...mapActions('template',['retriveTemplate']),
    ...mapActions("correspondence", ["retrieveCorrespondences","createCorrespondence", "updateCorrespondence", "deleteCorrespondence","filterCorrespondences"]),
  }
}
</script>

<style scoped lang="scss">
</style>