<template>
  <div v-if="localRequest" class="my-border text-break" style="position:relative">
    <v-btn
      class="ma-0 pa-0 exportbtn"
      icon
      @click="openInNew({ name: 'exportrequest' })"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <div class="ma-0 pa-0" v-on="on" v-bind="attrs">
            <v-icon>mdi-export-variant</v-icon>
          </div>
        </template>
        <span>{{ $t("export") }}</span>
      </v-tooltip>
    </v-btn>
    <div class="white pa-0 ma-0">
      <v-tabs
        background-color="white"
        v-model="currentItem"
        center-active
        grow
        slider-color="custom-color-accent"
        color="#046e8b"
        show-arrows
      >
        <v-tab v-for="item in items" :key="item.id" :href="'#' + item.tab">
          {{ $t(item.tab) }}
        </v-tab>
        <v-menu v-if="more.length" class="white">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
              {{ $t("More") }}
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list class="white">
            <v-list-item
              v-for="item in more"
              :key="item.id"
              @click="addItem(item)"
            >
              {{ $t(item.tab) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
      <v-divider></v-divider>
      <requestHeader :request="localRequest"></requestHeader>
      <v-divider></v-divider>
      <v-tabs-items
        v-model="currentItem"
        class="white"
        style="height: calc(100vh - 300px) !important; overflow-y: auto"
      >
        <v-tab-item key="1" value="details" class="pa-0">
          <detailsTable :request="localRequest" :print="false"></detailsTable>
        </v-tab-item>
        <v-tab-item key="2" value="attachments" class="pa-3">
          <attachmentTable :request="localRequest" :attachments="attachments"></attachmentTable>
        </v-tab-item>
        <v-tab-item key="3" value="documents" class="pa-3">
          <requestDocuments
            :request="localRequest"
          ></requestDocuments>
        </v-tab-item>
        <v-tab-item key="4" value="tasks" class="pa-3">
          <tasks :tasksFiltered="tasksFiltered"></tasks>
        </v-tab-item>
        <v-tab-item key="5" value="email" class="pa-3">
          <inbox :mails="mailByRequest" :request="localRequest"></inbox>
        </v-tab-item>
        <v-tab-item key="6" value="costs" class="pa-3">
          <costTable :request="localRequest"></costTable>
        </v-tab-item>
        <v-tab-item key="7" value="fees" class="pa-3">
          <feesTable :request="localRequest"></feesTable>
        </v-tab-item>
        <v-tab-item key="8" value="Courts" class="pa-3">
          <courtTab
            :current_req="localRequest"
            :courtsFiltered="courtsFiltered"
          ></courtTab>
        </v-tab-item>
        <v-tab-item key="9" value="Complaints" class="pa-3">
          <complaintTab
            :current_req="localRequest"
            :complaintsFiltered="complaintsFiltered"
          ></complaintTab>
        </v-tab-item>
        <v-tab-item key="10" value="Correspondence" class="pa-3">
          <correspondence
            :current_req="localRequest"
            :correspondencesFiltered="correspondencesFiltered"
          ></correspondence>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import attachmentTable from "@/components/request/AttachmentTable";
import detailsTable from "@/components/request/details";
import courtTab from "@/components/request/courtTab";
import complaintTab from "@/components/request/complaintTab";
import costTable from "../components/request/CostTable";
import feesTable from "../components/request/FeesTable";
import inbox from "../components/request/Inbox";
import requestHeader from "../components/request/RequestHeader";
import requestDocuments from "../components/request/RequestDocuments";
import tasks from "@/components/task/TasksTable";
import correspondence from "../components/request/Correspondence";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    attachmentTable,
    detailsTable,
    courtTab,
    complaintTab,
    costTable,
    feesTable,
    requestDocuments,
    inbox,
    requestHeader,
    tasks,
    correspondence,
  },
  data() {
    return {
      id: this.$route.params.id,
      tab: 0,
      taskFilters: {},
      complaintFilters: {},
      correspondenceFilters: {},
      courtFilters: {},
      consultationFilters: {},
      currentItem: "tab-1",
      items: [
        { tab: "details", id: "1" },
        { tab: "attachments", id: "2" }, 
        { tab: "documents", id: "3" },
        { tab: "tasks", id: "4" },
        { tab: "email", id: "5" },
      ],
      more: [
        { tab: "costs", id: "6" },
        { tab: "fees", id: "7" },
        { tab: "Courts", id: "8" },
        { tab: "Complaints", id: "9" },
       // { tab: "Correspondence", id: "10" },
      ]
    };
  },
  computed: {
    ...mapState("request", {
      requests: "requests",
    }),
    localRequest(){
      return this.requests?.filter(i=>parseInt(i.id) === parseInt(this.$route.params.id))[0]
    },
    ...mapGetters('attachment',{
      getAttachments: 'getAttachments'
    }),
    attachments() { return this.getAttachments(this.localRequest.id) },
    mailByRequest() { return this.$store.getters['email/mailByRequest'](this.$route.params.id) },
    ...mapState("configuration", {
      configurations: "configurations",
    }),
    ...mapGetters("task", {
      tasksFiltered: "tasksFiltered",
    }),
    ...mapState("task", {
      storeTaskFilters: "filter",
    }),
    ...mapGetters("complaint", {
      complaintsFiltered: "complaintsFiltered",
    }),
    ...mapGetters("correspondence", {
      correspondencesFiltered: "correspondencesFiltered",
    }),
    ...mapGetters("court", {
      courtsFiltered: "courtsFiltered",
    })
  },
  async created() {
    let tabItemmore = this.more.filter(item=>item.tab==this.$route.query.tab);
    let tabItem = this.items.filter(item=>item.tab==this.$route.query.tab);
    if(tabItemmore || tabItem)
      this.currentItem= this.$route.query.tab;
    await this.retriveRequestItem(this.$route.params.id);
    await this.retriveRequests();
    await this.retriveUsers();
    await this.retriveAttachments();
    if (this.localRequest) {
      this.setRequest(this.id);
      await this.retriveTasks();
      await this.retrieveAddresses();
      await this.retriveTypes();
      await this.retriveUsers();
      await this.retriveOrganizations();
      await this.retriveMails();
      await this.retriveComplaints();
      await this.retrieveCorrespondences();
      await this.retriveCourts();
      await this.retrieveConsultations();

      this.clearFilter();
      this.taskFilters.requestId = [
        { text: this.localRequest.reqCode, value: this.localRequest.id },
      ];
      this.complaintFilters.requestId = [
        { text: this.localRequest.reqCode, value: this.localRequest.id },
      ];
      this.correspondenceFilters.request_id = [
        { text: this.localRequest.reqCode, value: this.localRequest.id },
      ];
      this.courtFilters.requestId = [
        { text: this.localRequest.reqCode, value: this.localRequest.id },
      ];
      this.consultationFilters.req_code = [
        { text: this.localRequest.reqCode, value: this.localRequest.reqCode },
      ];
      this.filterTasks(this.taskFilters);
      this.filterComplaints(this.complaintFilters);
      this.filterCourts(this.courtFilters);
      this.filterConsultations(this.consultationFilters);
      this.filterCorrespondences(this.correspondenceFilters);
    }
  },
  watch:{
     '$route.query.tab'() {
        this.currentItem= this.$route.query.tab;
      }, 
      currentItem(val){
        this.$route.query.tab=val;
      },
      requests(val){
        val ? this.setRequestItem( this.localRequest ): '';
      }
  },
  methods: {
    ...mapActions("attachment", [
      "retriveAttachments"]
    ),
    ...mapActions("request", [
      "setRequestItem",
      "retriveRequestItem",
      "retriveRequests",
    ]),
    ...mapActions("task", ["retriveTasks", "filterTasks", "clearFilter"]),
    ...mapActions("complaint", [
      "retriveComplaints",
      "filterComplaints",
      "clearComplaintsFilter",
    ]),
    ...mapActions("correspondence", [
      "retrieveCorrespondences",
      "filterCorrespondences",
      "clearCorrespondencesFilter",
    ]),
    ...mapActions("court", [
      "retriveCourts",
      "filterCourts",
      "clearCourtsFilter",
    ]),
    ...mapActions("consultation", [
      "retrieveConsultations",
      "filterConsultations",
      "clearConsultationsFilter",
    ]),
    ...mapActions(["retriveTypes", "retriveUsers", "retriveOrganizations"]),
    ...mapActions("email", ["retriveMails", "setRequest"]),
    ...mapActions("address", ["retrieveAddresses"]),

    addItem(item) {
      const removed = this.items.splice(-1, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = item.tab;
      });
    },
    openInNew(r){
      let routeData = this.$router.resolve(r);
      window.open(routeData.href, '_blank');
    },
    getlookup(lookupname, val) {
      return this.configurations
        .filter(
          (filter) =>
            filter.LookUpName === lookupname && filter.LookUpKey == val
        )
        .map((item) => {
          let status = "";
          status =
            localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          return status;
        })[0];
    },
  },
};
</script>
<style scoped>
.exportbtn {
  position: absolute;
  top: -40px;
  right: -2px;
  margin: auto;
  z-index: 2;
}
</style>