<template>
  <div class="ma-0 pa-0">
    <v-row class="pa-0 ma-0 ">
      <v-col cols="12" class="ma-0 pa-0">
        <fileinputdrop
          :allowedTypes="allowedTypes"
          :allowedSize="allowedSize"
          :filesprop="files"
          :multiple="multiple"
          @upload-file="uploadFile"
        ></fileinputdrop>
      </v-col>
      <v-col cols="6" class="ml-auto text-right">
        <v-btn class="pa-2 ma-0 custom-color-accent" depressed
          :disabled="files.length === 0 || !$checkPermission('Documents.Upload')"
          @click="save"
          >{{ $t("Upload") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import pdfjs from "pdfjs-dist";
import fileinputdrop from "@/components/FileInputDrop";

export default {
  data() {
    return {
      files: [],
      now: moment().format("YYYY-MM-DD"),
      allowedTypes: "text/eml, text/css, text/csv, text/html, text/calendar, video/mpeg, video/3gpp, video/3gpp2, video/x-flv, video/mp4, video/3gpp, video/MP2T, video/quicktime, video/x-msvideo, video/x-matroska, video/x-ms-wmv, text/javascript, application/java-archive, application/x-mpegURL, application/docx, application/gzip, application/zip, application/x-7z-compressed, application/rtf, application/epub+zip, application/vnd.rar, application/vnd.debian.binary-package, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-fontobject, application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/pdf, application/sql, image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "500000000",
      multiple:true,
    };
  },

  created() {
    this.clearError();
  },
  components: {
    fileinputdrop,
  },
  computed: {
    ...mapState("documents", {
      loading: "loading",
    }),
    ...mapState("request", {
      request: "requestItem",
    }),
  },

  methods: {
    uploadFile(files) {
      if(this.$checkPermission('Documents.Upload'))
        this.files = files;
      else{
          this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
        this.files = []
      }
    },
    async save() {
      const formData = new FormData();
      formData.append("request_id", this.request.id);
      formData.append("arrLength", this.files.length);
      for (let index = 0; index < this.files.length; index++) {
        let file = this.files[index];
        var pdfUrl = URL.createObjectURL(file);
        await pdfjs.getDocument(pdfUrl).promise.then(async (pdf) => {
          formData.append("pages[" + index + "]", pdf.numPages);
        });
        formData.append("data[" + index + "]", file);
      }
      this.files = [];
      this.addDocuments(formData);
    },
    close() {
      this.clearError();
    },

    ...mapActions("documents", ["clearError", "addDocuments"]),
  },
};
</script>