<template >
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
    <v-card-title class="py-0 my-0 customlightgray">
      <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="7" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{attachments.length}}
                      </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="5" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn v-if="$checkPermission('Attachements.Create')" class="ma-0 pa-2 custom-color-accent mr-sm-1" depressed @click="template_dialog=true">{{$t("From Template")}}</v-btn>
                <v-btn v-if="$checkPermission('Attachements.Create')" class="ma-0 pa-2 custom-color-accent" depressed @click="dialog=true">{{$t("Upload")}}</v-btn>
            </v-col>
            
        </v-row>
      <!-- Dialog From Template Attachment -->
          <v-dialog v-model="template_dialog" class="elevation-0" width="1200px">
            <v-card class="customoffwhite">
              <v-card-title class="justify-center ma-0 customlightgray">
                <span class="font-weight-light">
                {{
                  $t("newattachment")
                }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                    <v-col cols="12" sm="12" md="12" class="mt-4">
                      <v-autocomplete
                          clearable
                          v-model="selectedEmailTemplate"
                          :items="templates.map(template=>{return {value:template.id,text:template.name}})"
                          :label="$t('selecttemplate')"
                          :placeholder="$t('selecttemplate')"
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                    <v-textarea
                        grow
                        outlined
                        v-model="attachment.AttachmentDescription"
                        :label="$t('description')"
                        :placeholder="$t('description')"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-menu  :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    prepend-inner-icon="mdi-calendar"
                                    v-model="attachment.AttachmentDate"
                                    v-on="on"
                                    outlined
                                    :label="$t('attachmentdate')"
                                    :placeholder="$t('attachmentdate')"
                                  ></v-text-field>
                                </template>
                                <v-date-picker dark :max="nowDate"
                                  v-model="attachment.AttachmentDate"
                                ></v-date-picker>
                        </v-menu>
                    </v-col>
                  <div class="overlay d-flex align-center justify-center" v-if="loadingUpload">
                    <v-progress-circular class="d-flex align-center" 
                      :size="70"
                      :width="7"
                      color="purple"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="custom-color-accent-text"
                  text
                  @click="close = template_dialog = false"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn class="custom-color-accent-text" :disabled="!selectedEmailTemplate" text @click="uploadTemplate(selectedEmailTemplate || '')">{{
                  $t("save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      <!-- Dialog add attachement-->
     
          <v-dialog v-model="dialog" class="elevation-0" width="1200px"> 
             <v-col cols="12" class="ma-auto pa-0">
                  <v-card class="customoffwhite">
                    <v-card-title class="justify-center ma-0 customlightgray">
                      <span class="font-weight-light" v-if="!editFlag">
                      {{
                        $t("newattachment")
                      }}</span>
                      <span class="font-weight-light" v-else>{{ $t("editattachment") }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                          <v-col cols="12" sm="12" md="12" v-if="!editFlag">
                            <fileinputdrop
                              :allowedTypes="allowedTypes"
                              :allowedSize="allowedSize"
                              :filesprop="files"
                              :multiple="multiple"
                              @upload-file="uploadFile"
                            ></fileinputdrop>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" v-else>
                            <v-text-field
                              v-model="attachment.AttachmentFileName"
                              :label="$t('document')"
                              :placeholder="$t('document')"
                              outlined
                              dense
                              readonly
                            ></v-text-field>
                            <v-btn @click="downloadAttachment(attachment)" class="custom-color-accent" depressed>{{
                              $t("Download File")
                            }}</v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                                grow
                                outlined
                                v-model="attachment.AttachmentDescription"
                                :label="$t('description')"
                                :placeholder="$t('description')"
                              ></v-textarea>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-menu  :close-on-content-click="true"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto">
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    prepend-inner-icon="mdi-calendar"
                                    v-model="attachment.AttachmentDate"
                                    v-on="on"
                                    outlined
                                    :label="$t('attachmentdate')"
                                    :placeholder="$t('attachmentdate')"
                                  ></v-text-field>
                                </template>
                                <v-date-picker dark :max="nowDate"
                                  v-model="attachment.AttachmentDate"
                                ></v-date-picker>
                              </v-menu>
                          </v-col>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="custom-color-accent-text"
                        text
                        @click="close = dialog = false"
                        >{{ $t("cancel") }}</v-btn
                      >
                      <v-btn class="custom-color-accent-text" :disabled="files.length==0&&!editFlag" text @click="save">{{
                        $t("save")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
              </v-col>
          </v-dialog>
      <!-- //Dialog add attachement-->
      <!-- Dialog delete confirm-->
        <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card class="customoffwhite">
                <v-card-title>{{ $t('delete') }}</v-card-title>
                <v-card-text
                  >{{ $t("Are you sure you want to delete this item?")}}</v-card-text
                >
                <v-card-actions>
                  <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{
                    $t("Close")
                  }}</v-btn>
                  <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{
                    $t("delete")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
          </v-dialog>
      <!-- //Dialog delete confirm-->
    </v-card-title>
    <v-divider></v-divider>
    <div v-if="loadingUpload" class="d-flex align-center justify-center" style="width:100%;height:200px;">
      <v-progress-circular class="d-flex align-center" 
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-data-table :mobile-breakpoint="1081"  v-else
      :headers="headers"
      :items="attachments"
      class="elevation-0 pa-0 ma-0 text--secondary customoffwhite"
      :search="search"
      :loading="loading"
      :loading-text="$t('loading')"
      :footer-props="{
        'items-per-page-text': $t('rowsperpage'),
      }"
    >
      <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
      <template v-slot:[`item.AttachmentDate`]="{item}">{{ item.AttachmentDate | formatDate }}</template>

      <v-spacer></v-spacer>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left v-if="$checkPermission('Attachements.Update')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon @click="downloadAttachment(item)">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("Download File")}}</span>
              </v-tooltip>
              <v-tooltip left v-if="$checkPermission('Attachements.Update')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon @click="setAction(item, 'Edit')">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
              <v-tooltip left v-if="$checkPermission('Attachements.Delete')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon @click="setAction(item, 'Delete')">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
  </v-card>
</template>
<script>
import {mapState, mapActions} from "vuex";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
import fileinputdrop from "@/components/FileInputDrop";
import html2pdf from "html2pdf.js";
export default {
  props: ["request","attachments"],
  components: {
    fileinputdrop,
  },
  data() {
    return {
      selectedEmailTemplate:'',
      nowDate: new moment().toISOString(),
      allowedTypes: "message/rfc822, text/css, text/csv, text/html, text/calendar, video/mpeg, video/3gpp, video/3gpp2, video/x-flv, video/mp4, video/3gpp, video/MP2T, video/quicktime, video/x-msvideo, video/x-matroska, video/x-ms-wmv, text/javascript, application/java-archive, application/x-mpegURL, application/docx, application/gzip, application/zip, application/x-7z-compressed, application/rtf, application/epub+zip, application/vnd.rar, application/vnd.debian.binary-package, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-fontobject, application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/pdf, application/sql, image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "500000000",
      multiple:false,
      files: [],
      search: "",
      itemsPerPpage: 10,
      editFlag: false,
      deleteItem: "",
      assign: false,
      selected: null,
      selectedItem: null,
      disabled: true,
      headers: [
        {
          text: this.$t("ID"),
          align: "left",
          sortable: true,
          value: "id",
        },
        { text: this.$t("filename"), value: "AttachmentFileName" },
        { text: this.$t("dateofattachment"), value: "AttachmentDate" },
        { text: "", value: "action", sortable: false },
      ],
      options : {
        margin: 0.3,
        filename: 'filename.pdf',
        image: {
          type: 'jpeg',
          quality: 0.95,
        },
        html2canvas: {
          scale: 2 ,
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait'
        }
      },
      loading: false,
      attachment: {},
      loadingUpload: false,
      dialog: "",
      error: "",
      dialogDelete: false,
      template_dialog: false
    };
  },
  watch: {
    requestAttachment: {
      handler(){
           Object.assign(this.attachment,this.requestAttachment);
      }, deep: true 
    },
  },
  computed: {
    ...mapState('template', {
      templates: 'templates'
    }),
    ...mapState("attachment", {
      user: "user",
      requestAttachment: "requestAttachment",
    }),
    ...mapState("request", {
      currentRequest: "requestItem",
    }),
    ...mapState("user", {
      userid: "id",
    }),

    tableTitle() {
      return this.$t("attachments");
    },
  },
  async created(){
    await this.retriveRequests();
    await this.retriveTemplate();
    await this.setAttachment();
    Object.assign(this.attachment,this.requestAttachment)
  },

  methods: {
    ...mapActions("attachment", [
      "setAttachment",
      "downloadAttachment",
      "addAttachment",
      "updateAttachment",
      "setFlagEdit",
      "deleteAttachment",
    ]),
    ...mapActions("request", [
      "setRequestStatus",
      "searchRequest",
      "retriveRequests",
    ]),
    filterContent(content){
      if(content){
        return content.replace(/\$(\w+.\w+)/g, (_, v) => {
          let string = v.split('.');
          if( string.length >= 1 ){
            if(string[0] === 'address'){
              return this.request[string[0]][string[1]];
            }
            else if(string[0] == 'date'){
              return moment().format("YYYY-MM-DD");
            }
            else if(string[0] == 'user'){
              return this.user[string[1]];
            }else{
              return this.request[string[0]];
            }
          }
          else{
            return this.request[string[0]];
          }
        });
      }
    },

    async uploadTemplate(template){
      this.loading=true;
      this.loadingUpload=true;
      let html = this.templates.filter(t=>t.id == template).map(i => i)[0];
      html.content = this.filterContent(html.content);
      let Content = '<html><head><style>.template table {border-spacing: 0;border-collapse: collapse;}.template td,th{width: 100%;border:1px solid;position: relative;padding:5px;}</style></head><body><div class="template" style=\'font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";font-size: .875rem;\'>'+html.content+'</div></body></html>';
      this.options.filename = this.filterContent(html.name);
      html2pdf().from(Content).set(this.options).toPdf().output('blob').then( (data) => {
        data.filename = this.options.filename;
        data.extension = 'pdf';
        let attachment = {}; 
        attachment['AttachmentFileName'] = data;
        attachment['filename'] = this.options.filename+'.pdf';
        attachment['ReqIdentity'] = this.request.id;
        attachment['OrgId'] = null;
        attachment['RequestId'] = this.request.id;
        attachment['AttachmentDescription'] = this.attachment.AttachmentDescription;
        attachment['UpdatedBy'] = this.user.id;
        attachment['AttachmentDate'] = this.attachment.AttachmentDate;
        console.log(attachment);
        let response = this.addAttachment(attachment);
        response.then(async ()=>{
            this.loading=false;
            this.loadingUpload=false;
            this.template_dialog=false;
            this.selectedEmailTemplate = '';
            this.setAttachment();
            this.$root.$emit("callAlert", {
              text: this.$t("itemAddedAlert"),
              type: "dark",
              alert: true
            });
          }) 
        })
    },
    uploadFile(files) {
      if(this.$checkPermission('Attachements.Upload')){
        this.files = files;
        this.attachment.AttachmentFileName = files[0];
      }
      else{
          this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
        this.files = []
      }
    },
    advanceSearch() {
      this.dialog = true;
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    newRequest() {
      this.$router.push({ name: "addRequest" });
    },
    async save() {
      this.loading = true;
      this.attachment.RequestId = this.request.id;
      if (this.editFlag) {
        let response = this.updateAttachment(this.attachment);
        response.then(async () => {
          this.loading = false;
          this.$root.$emit("callAlert", {
              text: this.$t("itemUpdatedAlert"),
              type: "dark",
              alert: true
            });
        });
        this.loading = false;
        this.editFlag = false;
      } else {
        this.attachment.ReqIdentity = this.request.id;
        this.attachment.filename = this.attachment.AttachmentFileName.name;
        console.log(this.attachment);
        let response = this.addAttachment(this.attachment);
        response.then(async () => {
          this.loading = false;
          this.$root.$emit("callAlert", {
              text: this.$t("itemAddedAlert"),
              type: "dark",
              alert: true
            });
        });
      }
      this.dialog = false;
      this.uploadFile([])
    },
    setAction(item, action) {
      switch (action) {
        case "Edit":
          this.dialog = true;
          this.editFlag = true;
          this.attachments.filter(i=> i.id === item.id ).map( j => {
            this.attachment = Object.assign({}, j);
          });
          break;
        case "Delete":
          this.deleteItem = item;
          this.dialogDelete = true;
          break;
        default:
        // code block
      }
    },
    async deleteConfig() {
      this.deleteAttachment(this.deleteItem.id).then(()=>{
        this.dialogDelete = false;
        this.$root.$emit("callAlert", {
          text: this.$t("itemRemovedAlert"),
          type: "dark",
          alert: true
        });
      })
      await axcessApi.deleteRequest(
        "attachment/" + this.deleteItem.id
      );
      this.dialogDelete = false;
      this.deleteReqAttachment({attachmentId: this.deleteItem.id,RequestId:this.request.id}).then(()=>{

          this.$root.$emit("callAlert", {
              text: this.$t("itemRemovedAlert"),
              type: "dark",
              alert: true
            });
      });
    },
    close() {
      this.assign = false;
      this.selected = null;
      this.loading = false;
    },
    ...mapActions('template', ['retriveTemplate'])
  },
};
</script>

<style lang="scss" scoped>
.row {
  cursor: pointer;
}

.overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>