<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{fees.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on='on' v-bind="attrs" icon  class="ma-auto pa-auto mx-2 text--primary" :to="{ name: 'invoice', params:{id: request.id} }" target="_blank" v-if="$checkPermission('Fees.Read')">
                      <v-icon>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t("print")}}</span>
                </v-tooltip>
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="dialog=true" v-if="$checkPermission('Fees.Create')">{{$t("newfee")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <!-- dialog add fee -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-card class="customoffwhite">
              <v-card-title class="justify-center ma-0 customlightgray">
                <span class="font-weight-light">{{
                  formTitle
                }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-autocomplete
                        :items="getLookup('Fees')"
                        :label="$t('Fee Type')"
                        :placeholder="$t('Fee Type')"
                        outlined
                        dense
                        v-model="editedItem.fee_type"
                        required
                      ></v-autocomplete>
                      <v-autocomplete
                        :items="getLookup('payMethod')"
                        :label="$t('paymethod')"
                        :placeholder="$t('paymethod')"
                        outlined
                        dense
                        v-model="editedItem.payMethod"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        dense
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            v-model="editedItem.transacDate"
                            v-on="on"
                            dense
                            :label="$t('date')"
                            :placeholder="$t('date')"
                          ></v-text-field>
                        </template>
                        <v-date-picker :max="nowDate"
                          dark
                          v-model="editedItem.transacDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
                      <v-text-field
                        :label="$t('unitprice')"
                        :placeholder="$t('unitprice')"
                        v-model.number="editedItem.unitPrice"
                        :rules="rules"
                        outlined
                        dense
                        type="number"
                        required
                        min="0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
                      <v-text-field
                        :label="$t('quantity')"
                        :placeholder="$t('quantity')"
                        :rules="rules"
                        outlined
                        dense
                        v-model.number="editedItem.quantity"
                        type="number"
                        min="0"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-autocomplete
                        :items="currency"
                        :label="$t('currency')"
                        :placeholder="$t('currency')"
                        outlined
                        v-model="editedItem.currency"
                        editable
                        dense
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-textarea
                        grow
                        outlined
                        dense
                        v-model="editedItem.comments"
                        :label="$t('comment')"
                        :placeholder="$t('comment')"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-chip
                  v-if="errored"
                  class="ma-2"
                  close
                  color="red"
                  text-color="white"
                  @click:close="errored = false"
                  >{{ error.message }}</v-chip
                >
                <v-spacer></v-spacer>
                <v-btn class="custom-color-accent-text" text @click="close">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn
                  :disabled="isDisable"
                  class="custom-color-accent-text"
                  text
                  @click="save"
                  >{{ $t("save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
      <!-- //dialog add fee -->
    <v-divider></v-divider>
    <v-data-table :mobile-breakpoint="1081" 
      :headers="headers"
      :items="fees"
      sort-by="id"
      class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
      :search="search"
      expanded.sync
      show-expand
      :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
    >
      <template v-slot:[`item.fee_type`]="{ item }">
        {{ getLookup('Fees',item.fee_type)[0]['text']}}
      </template>
      <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
      <template v-slot:[`item.transacDate`]="{ item }">
        <span>{{ formatDate(item.transacDate) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }" elevation="0">
        <td class=" my-side-border" :colspan="headers.length" elevation="0">
          <span class="font-weight-bold text--secondary"> {{$t("comment")}}: </span
          ><span class="text--secondary" v-if="item.comments!=null">{{ item.comments }}</span>
          <span class="text--secondary font-weight-light" v-else>{{$t("No comment.") }}</span>
        </td>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Fees.Update')">
                  <v-icon
                    @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$t("edit")}}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Fees.Delete')">
                  <v-icon
                    @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$t("delete")}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { CURRENCY } from "@/helpers/exports";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
import eventObserver from "@/plugins/eventObserver";
import { mapState } from "vuex";
import LogsHistory from "@/plugins/logs_history";

export default {
  props: ["request"],
  data() {
    return {
      dialog: false,
      search: "",
      nowDate: new moment().toISOString(),
      fees: this.request.fees,
      currency: CURRENCY.map((c) => c.currency),
      editedIndex: -1,
      error: "",
      errored: false,
      editedItem: {
        request_id: this.request.id,
        transacDate: moment().format("YYYY-MM-DD"),
        currency: "Canadian Dollar",
      },
      defaultItem: {
        request_id: this.request.id,
        transacDate: moment().format("YYYY-MM-DD"),
        currency: "Canadian Dollar",
      },
      rules: [
        (v) => !!v || this.$t("required"),
        (v) => v > 0 || this.$t("Value should be bigger than 0"),
      ],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: this.$t("feeid"),
          value: "id",
        },
        {
          text: this.$t("Fee Type"),
          value: "fee_type",
        },
        {
          text: this.$t("date"),
          value: "transacDate",
        },
        {
          text: this.$t("unitprice"),
          value: "unitPrice",
        },
        {
          text: this.$t("quantity"),
          value: "quantity",
        },
        {
          text: this.$t("paymethod"),
          value: "payMethod",
        },
        { text: "", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("newfee") : this.$t("editfee");
    },
    isDisable() {

      return (
        !this.editedItem.fee_type ||
        !this.editedItem.request_id ||
        !this.editedItem.currency ||
        !this.editedItem.quantity ||
        !this.editedItem.payMethod ||
        !this.editedItem.unitPrice
      );
    },

    tableTitle() {
      return this.$t("fees");
    },
    ...mapState("configuration", {
      configurations: "configurations",
    }),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem() {
      //console.log(item, this.isDisable);
    }
  },
  created() {},
  methods: {
    getLookup(column, val='') {
      return this.configurations
        .filter((filter) => {
          if(val){
            return filter.LookUpName === column && filter.LookUpKey === val
          }else{
            return filter.LookUpName === column
          }
        })
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    editItem(item) {
      this.editedIndex = this.fees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.fees.indexOf(item);
      if (
          confirm(this.$t("Are you sure you want to delete this item?")) &&
        this.fees.splice(index, 1)
      ) {
        try {
          await axcessApi.deleteRequest("/fee/" + item.id);
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editedIndex > -1) {
        try {
          const response = await axcessApi.patchRequest(
            "/fee/" + this.editedItem.id,
            this.editedItem
          ).then((r)=>{
            let $logs_history= new LogsHistory(this.fees,r.data.id,r.data,'fees');
            $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            return r;
          });
          await eventObserver.eventObserver("Fees", response.data);
          Object.assign(this.fees[this.editedIndex], response.data);
          this.close();
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      } else {
        try {
          const response = await axcessApi.postRequest("/fee", this.editedItem).then((r)=>{
            let $logs_history= new LogsHistory(this.fees,r.data.id,r.data,'fees');
            $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            return r;
          });
          eventObserver.eventObserver("Fees", response.data);
          this.fees.push(response.data);
          this.close();
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      }
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>

<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>