<template>
  <div>
  <v-card class="ma-0 pa-0 my-3 elevation-0 white">
    <div class="my-border">
      <v-card-title class="py-0 my-0 customlightgray">
          <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{request.release.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed :to="{ name: 'editor', params: { request_id: request.id } }" target="_blank">{{$t("openeditor")}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
        :items="request.release"
        :headers="headers"
        :options="options"
        class="elevation-0 pa-0 ma-0 text--secondary customoffwhite"
        :search="search"
        :loading-text="$t('Loading')"
        :footer-props="{
          'items-per-page-text': $t('rowsperpage'),
        }"
      >
        <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
        <template v-slot:[`item.created_at`]="{ item }">
          <div>{{ formatDate(item.created_at) }}</div>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.request_id`]="{ item }">{{
                getRequestId(item.request_id)
            }}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Read')">
                    <v-icon
                      @click="getWatermark(item.document_name)">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("download")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

    </div>
    <v-dialog max-width="500" v-model="watermark">
      <v-card class="customoffwhite">
        <form @submit.prevent="submit">
            <v-card-title class="justify-center ma-0 customlightgray">
                    <span class="font-weight-light">{{
                        $t('Set Watermark')
                      }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-4">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0 ma-0">
                    <v-text-field
                      :label="$t('Watermark Text')"
                      :placeholder="$t('Watermark Text')"
                      v-model="watermarkText"
                      dense
                      outlined
                      clearable
                      type="text"
                      required
                      @input="previewWatermark()">
                    </v-text-field>
                    <v-menu
                        v-model="colorpicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="watermarkColor"
                            :label="$t('Watermark Color')"
                            :placeholder="$t('Watermark Color')"
                            outlined dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-color-picker
                          :mode="mode"
                          justify="space-around"
                          v-model="watermarkColor"
                          dot-size="18"
                          show-swatches
                          class="customlightgray"
                          swatches-max-height="150"
                          @input="previewWatermark()">
                        </v-color-picker>
                    </v-menu>
                    <v-autocomplete v-model="watermarkPosition" :items="watermarkPositions" :label="$t('Watermark Position')" outlined dense :placeholder="$t('Watermark Position')"></v-autocomplete>
                    <v-text-field type="number" v-model.number="watermarkFont"
                      :label="$t('Font Size')"
                      :placeholder="$t('Font Size')"
                      :rules="[rules.required]"
                      dense
                      outlined
                      @input="previewWatermark()">
                    </v-text-field>
                    <v-text-field type="number" v-model.number="watermarkAngle"
                      :label="$t('Angle')"
                      :placeholder="$t('Angle')"
                      dense
                      outlined
                      @input="previewWatermark()">
                    </v-text-field>
                    <div id="preview" v-show="watermarkText" class="text-center"></div>
                  </v-col>
                </v-row>

                <div class=" d-flex align-center justify-center" v-if="downloadLoading">
                  <v-progress-linear
                    class="d-flex align-center"
                    :query="true"
                    :active="true"
                    value="100"
                    height="25"
                    color="custom-color-accent"
                  >
                    <strong class="white--text">{{progress}}%</strong>
                  </v-progress-linear>
                </div>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn class="custom-color-accent-text" text @click="watermark = false">{{
                  $t("cancel")
                }}</v-btn>
              <v-btn
                  class="custom-color-accent-text"
                  text
                  @click="getReleaseDoc()"
              >{{ $t("save") }}</v-btn
              >
            </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-card>
  </div>
</template>
<script>
import { mapState,mapGetters} from "vuex";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
import axios from "axios";
import jsPDF from "jspdf";
import pdfjs from "pdfjs-dist";

export default {
  data() {
    return {
      watermarkPositions:[
        {value:'top', text:'Top'},
        {value:'bottom', text:'Bottom'},
        {value:'middle', text:'Middle'},
        {value:'leftTop', text: 'Left Top'},
        {value:'rightTop', text: 'Right Top'},
        {value:'leftMiddle', text: 'Left Middle'},
        {value:'rightMiddle', text: 'Right Middle'},
        {value:'leftBottom', text: 'Left Bottom'},
        {value:'rightBottom', text: 'Right Bottom'}
      ],
      watermarkPosition:'middle',
      watermarkColor:"#046E8BBF",
      watermarkFont:14,
      mode:'rgba',
      wX:0,
      wY:50,
      watermarkAngle:0,
      progress:0,
      downloadLoading:false,
      search: "",
      dialog: false,
      colorpicker: false,
      selected: [],
      options: {
        sortDesc: true,
      },
      headers: [
        { text: this.$t("requestid"), value: "request_id" },
          { text: this.$t("filename"), value: "document_name" },
        { text: this.$t("datecreated"), value: "created_at" },

        { text: "", value: "action", sortable: false },
      ],
      address: [],
      editedIndex: -1,
      watermark: false,
      watermarkText:'',
      docName:'',
      rules:{
        required: value => !!value || this.$t('required'),
      },
      canvas:''
    };
  },
  created() {
    this.$store.dispatch("consultation/loadReleaseDoc");
  },
  computed: {
    ...mapState("request", {
      requests:"requests"
    }),
    ...mapGetters('request',{
      getRequestItem: "getRequestItem"
    }),
    request(){
      return this.getRequestItem(this.$route.params.id)
    },
    tableTitle() {
      return this.$t("Release Documents");
    },
  },
  methods: {
    getWatermark(docname){
      this.clear();
      this.watermark = true;
      this.docName = docname;
    },
    clear(){
       this.watermarkText='';
       this.progress=0;
       this.watermarkAngle=0;
       this.watermarkFont=14;
       this.watermarkColor='#046E8BBF';
       this.watermarkPosition='middle';
    },
    getRequestId(id){
      return this.requests.filter(i => i.id === id ).map(j=>j.reqCode)[0];
    },
    getReleaseDoc() {
      this.downloadLoading=true;
      let $this = this;
      return new Promise(() => {
        axcessApi
          .getRequest("/releasedoc/" + this.request.id + "/docid/" + this.docName, {
            responseType: "arraybuffer",
          })
          .then((res) => {
            let blob = new Blob([res.data], { type: "application/*" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = this.docName;
            link._target = "blank";

            var jspdf = new jsPDF({
              orientation: "P",
              format: "letter",
              unit: "pt",
            });
            const preview = `${axios.defaults.baseURL}/releasedoc/${this.request.id}/docid/${this.docName}`;
            var loadingTask = pdfjs.getDocument(preview);
            loadingTask.promise.then(async (pdf) => {
              let total_pages = pdf.numPages;
              let increment_page = 1;
              for (let i = 1; i <= pdf.numPages; i++) {
                  await pdf.getPage(i).then(async (page) =>{
                    let viewport = await page.getViewport({ scale: 1 });
                    let canvas = await document.createElement("canvas");
                    let ccontext = await canvas.getContext("2d");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    let renderContext = {
                      canvasContext: ccontext,
                      viewport: viewport,
                    };
                   await page.render(renderContext).promise.then(async () => {
                      //Setup Page numbers
                      await jspdf.setFontSize(11)
                      await jspdf.setTextColor(0,0,0);
                      await jspdf.setFont('Helvetica');
                      await jspdf.setFillColor(255,255,255);
                      await jspdf.setDrawColor(255, 255, 255);
                      var dataUrl = await canvas.toDataURL();
                      await jspdf.setPage(i);
                      await jspdf.addPage(612, 791);
                      await jspdf.addImage(
                        dataUrl,
                        "JPEG",
                        0,
                        0,
                        612,
                        791,
                        undefined,
                        "FAST"
                      );
                      var s = await String(increment_page++);
                      while (s.length <  3) {s = "0" + s;}
                      await jspdf.setFontSize(9);
                      await jspdf.setFont('Helvetica');
                      await jspdf.setTextColor(255,255,255,0.2);
                      await jspdf.text(452, (777), $this.request.reqCode +' PAGE No: '+ s);
                      await $this.setupXY();
                      await jspdf.setTextColor(150);
                      if($this.canvas)
                        await jspdf.addImage(
                            $this.watermarkUrl(),
                            "JPEG",
                            $this.wX,
                            $this.wY,
                            $this.canvas.width,
                            $this.canvas.height,
                            undefined,
                            "FAST"
                        );
                      //jspdf.text('Watermark Text', 100, jspdf.internal.pageSize.height * (50/100), {angle: 45,middle:10 });
                      //setTimeout(resolve, Math.random());
                      if (total_pages === i) {
                        await jspdf.deletePage(1);
                        await jspdf.save($this.docName);
                      }
                    });
                  });
                  let v=i*100/pdf.numPages;
                  this.progress=Math.ceil(v);
              }
              this.watermark = false;
              this.downloadLoading=false;
              this.clear();

            });

            window.URL.createObjectURL(blob);
          })
          .catch(() => {
              this.downloadLoading=false;
          });
      }
      );
    },
    formatDate(value) {
      return value != null ? moment(value).format("YYYY-MM-DD") : "";
    },
    previewWatermark() {

      let canvas = document.createElement("CANVAS");
      let context = canvas.getContext("2d");
      context.font = this.watermarkFont+'px serif';
      this.watermarkFont = parseInt(this.watermarkFont);
      const metrics = context.measureText(this.watermarkText);
      let angle = (this.watermarkAngle - 90) / 180 * Math.PI;
      let w = Math.abs(metrics.width * Math.sin(angle));
      let h = Math.abs(metrics.width * Math.cos(angle));
      canvas.width=w+this.watermarkFont;
      canvas.height=h+this.watermarkFont;
      context.textAlign = "center";
      context.textBaseline = "middle";
      let ox = canvas.width / 2;
      let oy = canvas.height / 2;
      context.translate(ox, oy);
      context.rotate(this.watermarkAngle * Math.PI / 180);
      context.translate(-ox, -oy);
      context.font=this.watermarkFont+'px serif';
      context.fillStyle = this.watermarkColor;
      context.fillText(this.watermarkText,ox,oy);
      document.getElementById('preview').innerHTML='';
      document.getElementById('preview').appendChild(canvas);
      this.canvas = canvas;
    },
    watermarkUrl(){
      return  this.canvas.toDataURL();
    },
    setupXY(){
      let w = this.canvas.width;
      let h = this.canvas.height;

      switch (this.watermarkPosition){
        case 'top':
          this.wX = (612*0.5) - (w/2);
          this.wY = 0;
          break;
        case 'bottom':
          this.wX = (612*0.5) - (w/2);
          this.wY = (791-h);
          break;
        case 'middle':
          this.wX = (612*0.5) - (w/2);
          this.wY = (791*0.5) - (h/2);
          break;
        case 'leftTop':
          this.wX = 0;
          this.wY = 0;
          break;
        case 'rightTop':
          this.wX = 612-w;
          this.wY = 0;
          break;
        case 'leftMiddle':
          this.wX = 0;
          this.wY = (791*0.5) - (h/2);
          break;
        case 'rightMiddle':
          this.wX = 612-w;
          this.wY = (791*0.5) - (h/2);
          break;
        case 'leftBottom':
          this.wX = 0;
          this.wY = (791-h);
          break;
        case 'rightBottom':
          this.wX = 612-w;
          this.wY = (791-h);
          break;
      }
    }
  },
};
</script>

<style scoped>
.status-chip {
  width: 100px;
}
.overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>