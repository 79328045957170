<template>
    <div class="pa-0 pb-4 ma-0">
            <document-table
              v-if="request.reqCode"
              :request="request"
              :documents="unregDoc"
            ></document-table>
            <request-doc-table
              v-if="request.reqCode"
              :request="request"
              :documents="regDoc"
            ></request-doc-table>
            <consultation-table
              v-if="request.reqCode"
              :request="request"
              :documents="consultDoc"
            ></consultation-table>
            <pco-consultation-table :request="request"
              :documents="pcoConsultDoc"
            ></pco-consultation-table>
            <release-doc-table
              v-if="request.reqCode"
              :request="request"
              :documents="consultDoc"
            ></release-doc-table>
    </div>
</template>
<script>
import documentTable from "@/components/request/DocumentTable";
import registeredDocumentTable from "@/components/request/RegisteredDocumentTable";
import consultationTable from "@/components/consultation/ConsultationTable";
import releaseDocTable from "@/components/consultation/ReleaseDocTable";
import PCOConsultationTable from "@/components/consultation/PCOConsultationTable";
import { mapActions } from "vuex";
export default ({
    props: ["request","consultationsFiltered"],
    components: {
      "document-table"        : documentTable,
      "request-doc-table"     : registeredDocumentTable,
      "consultation-table"    : consultationTable,
      "release-doc-table"     : releaseDocTable,
      "pco-consultation-table": PCOConsultationTable
  },
  computed:{
    regDoc() {
      return this.$store.getters['documents/getRegDocs'](this.$route.params.id)
    },
    unregDoc() {
      return this.$store.getters['documents/getUnRegDocs'](this.$route.params.id)
    },
    consultDoc() {
      return this.$store.getters['consultation/getConsultDoc'](this.$route.params.id)
    },
    pcoConsultDoc() {
      return this.$store.getters['pcoconsultation/getPcoConsultDoc'](this.$route.params.id)
    },
    releasedDoc() {
      return this.$store.getters['pcoconsultation/getPcoConsultDoc'](this.$route.params.id)
    },
  },
  created() {
    this.retrieveDocuments();
  },
  methods: {
      ...mapActions('documents',[
          "retrieveDocuments"
      ])
  }
})
</script>
<style scoped>

</style>
