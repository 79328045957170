<template>
<v-row class="ma-0 pa-0 elevation-0">
  <v-col cols="12" md="4" lg="3" class="pa-0 ma-0  mb-md-0 my-border" >
    <v-list three-line class="pa-0 ma-0">
      <div class="">
        <div class="pa-0 ma-0 customlightgray">
          <v-subheader class="pa-4 ma-0 font-weight-bold title text-capitalize" >
            <div class="font-weight-medium text-left text--secondary">  
              <div class="ma-auto pa-auto">
                <span class="ma-0 pa-1">{{$t("history")}}</span>
                <span style=" border-radius: 22px; font-size:0.6em;"
                    class="custom-color-accent pa-1">{{mails.length}}
                </span>
              </div>
            </div>
          </v-subheader>
        </div>
        <v-divider></v-divider>
         <div class="pa-0 ma-0 white" v-if="mails">
            <div class="ma-0 pa-0" style="height:calc(100vh - 404px) !important;overflow-y:auto;" v-if="$checkPermission('Emails.Read')">
              <div class="pa-0 ma-0" :id="item.id" v-for="(item, index) in mails" :key="index" >
                
                  <v-list-item :key="index" 
                    @click="setEmail(item)"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-avatar v-bind="attrs" v-on="on" class="custom-color-accent text-uppercase" size="45">
                          {{ item.user.first_name.charAt(0) }}{{ item.user.last_name.charAt(0)}}
                        </v-list-item-avatar>
                      </template>
                      <span class="text-capitalize">{{item.user.first_name}} {{item.user.last_name}}</span>
                    </v-tooltip>
                    <v-list-item-content>
                      <v-list-item-title class="text-capitalize">{{item.data.subject}}</v-list-item-title>
                      <v-list-item-subtitle><span class="text--primary">@{{item.user.username}}</span> - {{ clearHtml(item.data.body) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                  class="mx-6"
                  :key="'d-'+index"
                  :inset="item.inset"
                  ></v-divider>
              </div>
            </div>
        </div>
      </div>
    </v-list>
  </v-col>
  <v-col cols="12" md="8" lg="9" class="pa-0 ma-0  mb-md-0 my-border">        
      <v-list two-line class="pa-0 ma-0">
        <div class="pa-0 ma-0">
          <div class="pa-0 ma-0 customlightgray">
            <v-subheader class=" pa-0 px-4 ma-0 font-weight-bold title">
              <div class="font-weight-medium text-left text--secondary">  
                <div class="ma-auto pa-auto">
                  <span class="ma-auto pa-auto">{{$t("email")}}</span>
                </div>  
              </div>
              <v-spacer></v-spacer>
              <v-btn @click="openAttach()" v-if="$checkPermission('Emails.Create')" class="ml-auto mr-2 pa-auto ma-0 custom-color-accent-text transparent" depressed> <v-icon class="mr-2">mdi-attachment</v-icon> {{$t("attach")}}</v-btn>
              <v-menu
                rounded="lg" content-class="elevation-1"
                offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    class="custom-color-accent" depressed
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-email-edit-outline</v-icon>{{$t("compose")}}
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item link @click="clear()">
                    <v-list-item-title>{{$t("newemail")}}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item link @click="setTemplateEmail()">
                    <v-list-item-title>{{$t("templateemail")}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-subheader>
          </div>
          <v-divider></v-divider>
          <div class="pa-0 ma-0 white" v-if="isNotSelected!=null">
            <div class="ma-0 pa-0 px-4" style="height:calc(100vh - 404px) !important;overflow-y:auto;">
            <v-list-item
              class="pa-0 ma-0">
                <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col cols="12" sm="2" class="font-weight-medium pa-0 ma-auto">
                    <span class="pa-0 ma-0 text-capitalize">{{$t("from")}}</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0">
                      <v-text-field
                        v-model="selectedEmail.user.username"
                        outlined
                        class="customoffwhite"
                        dense
                        hide-details
                        disabled
                      ></v-text-field>
                  </v-col>
                </v-row>              
            </v-list-item>
            <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col cols="12" sm="2" class="font-weight-medium pa-0 ma-auto">
                    <span class="pa-0 ma-0 text-capitalize">{{$t("to")}}</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0">
                    <v-combobox
                        v-model="selectedEmail.data.sendTo"
                        :items="Array.from(alladdress)"
                        chips
                        :search-input.sync="additionalTo"
                        hide-details
                        :readonly="!isNotSelected"
                        :disabled="isUpdating"
                        dense
                        persistent-hint
                        outlined
                        return-object
                        @change="validateInput"
                        multiple>
                      <template v-slot:selection="data">
                        <v-chip
                            style="width:auto"
                            class="custom-color-accent"
                            :input-value="data"
                            close
                            @click:close="removeTo(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{$t("No results matching")}} "<strong>{{ additionalTo }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
            </v-list-item> 
            <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col cols="12" sm="2" class="font-weight-medium pa-0 ma-auto">
                    <span class="pa-0 ma-0 text-capitalize">{{$t("cc")}}</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0">
                    <v-combobox
                      v-model="selectedEmail.data.cc"
                      :disabled="isUpdating"
                      :items="Array.from(alladdress)"
                      chips
                      hide-details
                      dense
                      :readonly="!isNotSelected"
                      outlined
                      return-object
                      :search-input.sync="additionalCc"
                      @change="validateInput"
                      multiple>
                      <template v-slot:selection="data">
                        <v-chip
                          class="custom-color-accent"
                          style="width:auto"
                          :input-value="data"
                          close
                          @click:close="removeCc(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>

                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{$t("No results matching")}} "<strong>{{ additionalCc }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
            </v-list-item> 
            <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col cols="12" sm="2" class="font-weight-medium pa-0 ma-auto">
                    <span class="pa-0 ma-0 text-capitalize">{{$t("bcc")}}</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0">
                    <v-combobox
                      v-model="selectedEmail.data.bcc"
                      :disabled="isUpdating"
                      :items="Array.from(alladdress)"
                      chips
                      hide-details
                      :readonly="!isNotSelected"
                      dense
                      :search-input.sync="additionalBcc"
                      outlined
                      return-object
                      @change="validateInput"
                      multiple>
                      <template v-slot:selection="data">
                        <v-chip
                          class="custom-color-accent"
                          style="width:auto"
                          :input-value="data"
                          close
                          @click:close="removeBcc(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{$t("No results matching")}} "<strong>{{ additionalBcc }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
            </v-list-item>
            <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col cols="12" sm="2" class="font-weight-medium pa-0 ma-auto">
                    <span class="pa-0 ma-0 text-capitalize">{{$t("subject")}}</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="ma-0 pa-0">
                      <v-text-field
                        v-model="selectedEmail.data.subject"
                        outlined
                        :readonly="!isNotSelected"
                        class=""
                        dense
                        hide-details
                      ></v-text-field>
                  </v-col>
                </v-row>
            </v-list-item>
            <v-list-item v-if="attachments.length>0"
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="(item,i)  in attachments"
                    :key="i">
                      <v-input
                      class="my-border customoffwhite ma-0 pa-3"
                      :messages="bytesToSize(item.size)"
                      prepend-icon="mdi-file"
                      >
                        <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                        <v-icon class="ml-2 clickable" v-if="$checkPermission('Emails.Create') && isNotSelected" @click="removeAttachment(item)"
                          >mdi-delete</v-icon
                      >
                      </v-input>
                  </v-col>
                    </v-row>
            </v-list-item> <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <el-tiptap  :lang="$i18n.locale" :readonly="!isNotSelected" v-model="selectedEmail.data.body" class="white" :extensions="extensions"></el-tiptap>
                </v-row>
            </v-list-item>
            <v-list-item
              class="pa-0 ma-0">
              <v-row class="pa-0 ma-0 text-center text-sm-left">
                  <v-btn v-if="isNotSelected && $checkPermission('Emails.Create')" :disabled="selectedEmail.data.body=='' || selectedEmail.data.subject=='' || selectedEmail.data.sendTo.length==0" @click="send()" class="ml-auto pa-auto ma-0 custom-color-accent" depressed>
                    <v-icon class="mr-2">mdi-send</v-icon> {{$t("send")}}</v-btn>
                   <v-progress-linear v-if="sending" indeterminate></v-progress-linear>
                </v-row>
            </v-list-item>
            </div>
          </div>
          <div class="pa-0 ma-0 white" v-else>
            <v-card class="d-block d-sm-flex justify-center align-center transparent" flat style="height:calc(100vh - 404px) !important;overflow-y:auto;">
              <v-menu
                rounded="lg" content-class="elevation-1"
                offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    class="custom-color-accent" depressed
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-email-edit-outline</v-icon>{{$t("compose")}}
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item link @click="clear()">
                    <v-list-item-title>{{$t("newemail")}}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item link @click="setTemplateEmail()">
                    <v-list-item-title>{{$t("templateemail")}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card>
          </div>
        </div>
      </v-list>
  </v-col>
   <v-dialog v-model="dialog" class="elevation-0" width="1200px">
      <v-col cols="12" class="ma-auto pa-0">
                  <v-card class="customoffwhite">
                    <v-card-title>
                      <span class="headline ma-auto"><v-icon large>mdi-attachment</v-icon></span>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12" sm="12" md="12">
                            <fileinputdrop
                              :allowedTypes="allowedTypes"
                              :allowedSize="allowedSize"
                              :filesprop="files"
                              :multiple="multiple"
                              @upload-file="uploadFile"
                            ></fileinputdrop>
                          </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="custom-color-accent-text"
                        text
                        @click="close = dialog = false"
                        >{{ $t("cancel") }}</v-btn
                      >
                      <v-btn class="custom-color-accent-text" :disabled="files.length==0" text @click="saveAttach()">{{
                        $t("save")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
              </v-col>
          </v-dialog>
    <v-dialog v-model="dialogTemplate" max-width="800px">
      <v-card class="customoffwhite">
        <v-card-title>
          <span class="headline ma-auto">{{$t('templateemail')}}</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            clearable
            v-model="selectedEmailTemplate"
            :items="templates.map(template=>{return {value:template.id,text:template.name}})"
            :label="$t('selecttemplate')"
            :placeholder="$t('selecttemplate')"
            dense
            outlined
            required
          ></v-autocomplete>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialogTemplate = false">{{$t('cancel')}}</v-btn>
          <v-btn class="custom-color-accent-text" :disabled="!selectedEmailTemplate" @click="fillEmailTemplate()" text >{{$t('save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-row>
</template>
<script>
import { ElementTiptap } from 'element-tiptap';
import moment from "moment";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags
import {mapState,mapActions,mapGetters} from 'vuex';
import fileinputdrop from "@/components/FileInputDrop";
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TextAlign,
  History,
  Image,
  CodeView,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Link
} from 'element-tiptap';
export default {
    props: ["mails","request"],

   components: {
      'el-tiptap': ElementTiptap,
      fileinputdrop
   },
  data() {
    return {
      additionalTo: null,
      additionalCc: null,
      additionalBcc: null,
      docContent:"",
      previewDoc: false,
      selectedEmail:{
        requestId:this.request.id,
        userId:JSON.parse(localStorage.getItem('user')).id,
        date_sent:moment().format("YYYY-MM-DD"),
        data:{
          sendTo:[this.$store.state.request.requestItem.address.Email],
          subject:"",
          body:"",
          replyTo:JSON.parse(localStorage.getItem('user')).email,
          cc:[],
          bcc:[],
          attachments:[]
        },
        user:JSON.parse(localStorage.getItem('user') || null)
        },
      isUpdating: false,
      valid: true,
      selectedTemplate: null,
      selectedEmailTemplate: null,
      dialog:false,
      dialogTemplate:false,
      sending:false,
      isNotSelected:null,
      now: moment().format("YYYY-MM-DD"),
      allowedTypes: "text/css, text/csv, text/html, text/calendar, video/mpeg, video/3gpp, video/3gpp2, video/x-flv, video/mp4, video/3gpp, video/MP2T, video/quicktime, video/x-msvideo, video/x-matroska, video/x-ms-wmv, text/javascript, application/java-archive, application/x-mpegURL, application/docx, application/gzip, application/zip, application/x-7z-compressed, application/rtf, application/epub+zip, application/vnd.rar, application/vnd.debian.binary-package, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-fontobject, application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/pdf, application/sql, image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "500000000",
      multiple:true,
      attachments: [],
      files: [],
      extensions: [
            new Doc(),
            new Text(),
            new Paragraph(),
            new Heading({ level: 5 }),
            new Bold({ bubble: true }), // render command-button in bubble menu.
            new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
            new Italic(),
            new Strike(),
            new ListItem(),
            new BulletList(),
            new OrderedList(),
            new TextAlign(),
            new History(),
            new Image(),
            new Link({bubble:true}),
            new TableHeader(),
            new TableCell(),
            new TableRow(),
            new Table({
            resizable: true,
            }),
            new CodeView({
              codemirror,
              codemirrorOptions: {
                  styleActiveLine: true,
                  autoCloseTags: true
              }
            })
        ],
    }},

    async created() {
      await this.retriveTemplate()
      await this.retriveMails();
    },
    mounted() {

    },
    watch: {
        isUpdating (val) {
          if (val) {
            setTimeout(() => (this.isUpdating = false), 3000)
          }
        },
        request: {
          handler(){
          this.clear();
          }, deep: true
        }
      },

    computed: {
      alladdress() {
        return new Set(this.$store.state.address.addresses.map(i=>i.Email).concat(this.users.map(i=>i.email)));
      },
      ...mapState({
        users: "users"
      }),
      ...mapGetters('template', {
        templates: 'getTemplatebyLanguage'
      }),
      ...mapState('request', {
        currentRequest: 'requestItem',
        user: "user"
      })
    },

    methods: {
      validateInput(item){
        if(item.length){
          let lastEle = item.pop();
          let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
          if(regex.exec(lastEle)){
            item.push(lastEle);
          }
        }
      },
      ...mapActions("email", ["sendEmail","retriveMails"]),
      ...mapActions('template',['retriveTemplate']),
      removeBcc (item) {
        this.selectedEmail.data.bcc = this.selectedEmail.data.bcc.filter(a=>a!=item);
      },
       removeCc (item) {
        this.selectedEmail.data.cc = this.selectedEmail.data.cc.filter(a=>a!=item);
      },
      removeTo (item) {
        this.selectedEmail.data.sendTo = this.selectedEmail.data.sendTo.filter(a=>a!=item);
      },
      setEmail(item){
        if(this.$checkPermission('Emails.Read') && item){
          this.clear();
          this.isNotSelected = false;
          this.selectedEmail = JSON.parse(JSON.stringify(item));
          this.selectedEmail.data.sendTo = item.data.to?.map(i=>i.address);
          this.selectedEmail.data.cc = item.data.cc?.map(i=>i.address);
          this.selectedEmail.data.bcc = item.data.bcc?.map(i=>i.address);
          this.attachments = this.selectedEmail.data.attachment.map(a=>({ 'name': a.options.as, 'size':a.options.size, 'url': a.file }))
          document.getElementById(item.id).classList.add("selected");
        }
      },
      send(){
         if(this.selectedEmail.data.body && this.selectedEmail.data.subject && this.selectedEmail.data.sendTo.length){
           this.sending=true;

           const formData = new FormData();
           formData.append("arrLength", this.attachments.length);
           for (let index = 0; index < this.attachments.length; index++) {
             let file = this.attachments[index];
             formData.append("attachment[" + index + "]", file);
           }
           formData.append('requestId', this.request.id);
           formData.append('userId', JSON.parse(localStorage.getItem('user')).id);
           formData.append('date_sent', moment().format("YYYY-MM-DD"));
           formData.append( 'sendTo', this.selectedEmail.data.sendTo );
           formData.append( 'subject', this.selectedEmail.data.subject );
           formData.append( 'replyTo', JSON.parse(localStorage.getItem('user')).email );
           formData.append( 'cc', this.selectedEmail.data.cc );
           formData.append( 'bcc', this.selectedEmail.data.bcc );
           formData.append( 'user', JSON.parse(localStorage.getItem('user')) );
           formData.append( 'body', this.selectedEmail.data.body );

            this.sendEmail(formData).then((response)=>{
              this.sending=false;
              if(response.data!="error"){
                this.$root.$emit("callAlert", {text:"Email Sent Successfully", type:"success", alert:true});
                this.clear();
              }
              else
                this.$root.$emit("callAlert", {text:"Email Not Send", type:"warning", alert:true});
            });

           }
           else
               this.$root.$emit("callAlert", {text:"Subject and body should be filled", type:"warning", alert:true});

          },
      removeAttachment(item){
          const index = this.attachments.indexOf(item);
          if (index > -1) {
            this.attachments.splice(index, 1)
              this.$root.$emit("callAlert", {
                text: this.$t("fileRemovedAlert"),
                type: "dark",
                alert: true
              });
           }

      },
      saveAttach(){
        this.isNotSelected=true;
        Object.assign(this.attachments,this.files);
        this.files=[];
        this.dialog=false;
      },
      setTemplateEmail(){
        this.dialogTemplate=true;
      },
      async fillEmailTemplate(){
        if( await this.clear() ){
          let template = await this.templates.filter( item => item.id === this.selectedEmailTemplate )[0];
          this.selectedEmail.data.body = this.filterContent(template.content);
          this.selectedEmail.data.subject = this.filterContent(template.name);
          this.dialogTemplate = false;
          this.isNotSelected = true;
        }

      },
      filterContent(content){
        if(content){
          return content.replace(/\$(\w+.\w+)/g, (_, v) => {
            let string = v.split('.');
            if( string.length >= 1 ){
              if(string[0] === 'address'){
                return this.currentRequest[string[0]][string[1]];
              }
              else if(string[0] == 'date'){
                return moment().format("YYYY-MM-DD");
              }
              else if(string[0] == 'user'){
                return this.user[string[1]];
              }else{
                return this.currentRequest[string[0]];
              }
            }
            else{
              return this.currentRequest[string[0]];
            }
          });
        }
      },
      openAttach(){
        this.dialog=true;
        if(!this.isNotSelected){
          this.clear()
        }
      },
      bytesToSize(size) {
        let bytes = size
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "0 Byte";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
      },
      uploadFile(files) {
        if(this.$checkPermission('Attachements.Upload')){
          this.files = files;
        }
        else{
            this.$root.$emit("callAlert", {
              text: this.$t("notallowed"),
              type: "dark",
              alert: true
            });
          this.files = []
        }
      },
      clear(){
            this.isNotSelected=true;
            this.mails.forEach(element => {  document.getElementById(element.id).classList.remove("selected");  } )
            this.attachments=[],
            this.selectedEmail = {
            requestId:this.request.id,
            userId:JSON.parse(localStorage.getItem('user')).id,
            date_sent:moment().format("YYYY-MM-DD"),
            data:{
              sendTo:[this.$store.state.request.requestItem.address.Email],
              subject:"",
              body:"",
              replyTo:JSON.parse(localStorage.getItem('user')).email,
              cc:[],
              bcc:[],
              attachment:[]
            },
            user:JSON.parse(localStorage.getItem('user') || null)
            }
            return true;
      },
      clearHtml(text){
        text= text?.replace( /(<([^>]+)>)/ig, '');
        text=text?.replace(/&nbsp;/g, ' ');
        return text;
      },
    },
}
</script>
<style>
.el-tiptap-editor__menu-bar,
.el-tiptap-editor__content,
.el-tiptap-editor__footer,
.el-tiptap-editor__command-button,
.el-dropdown{
  background: inherit !important;
  color: inherit !important;
}
.el-tiptap-editor__command-button:hover{
  background: rgba(143, 142, 142, 0.363) !important;
}
.el-tiptap-editor__command-button--active{
  color: white !important;
  background: rgb(95, 95, 95) !important;
}
.el-tiptap-dropdown-menu__item--active,.CodeMirror-activeline-background {
  color: white !important;
  background: rgba(122, 122, 122, 0.5) !important;
}
.el-popper {
  background: rgb(63, 63, 63) !important;
}
.el-dropdown-menu__item,.el-tiptap-popper__menu__item,.el-dialog__header,.el-dialog__body,.el-dialog__title,.el-form-item__label,.el-message-box__title{
  color:white !important;
}
.el-dropdown-menu__item:hover,.el-tiptap-popper__menu__item:hover {
  color:white !important;
  background: rgb(59, 59, 59) !important;
}
.el-dialog,.el-message-box,.el-input__inner,.CodeMirror,.CodeMirror-gutters,.el-tiptap-editor__menu-bubble{
  background: rgb(49, 49, 49);
  color: white !important;
}
</style>