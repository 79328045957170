<template>
  <v-card class="ma-0 pa-0 my-3 elevation-0 white">
    <div class="my-border">
      <v-card-title class="py-0 my-0 customlightgray">
          <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{documents.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed :to="{ name: 'editor', params: { request_id: request.id } }" target="_blank">{{$t("openeditor")}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-dialog v-model="dialog" max-width="800px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
                <span class="font-weight-light">{{
              formTitle
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="pa-0 ma-0">
              <v-row>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-text-field
                    class="px-2"
                    v-model="editedItem.name"
                    :label="$t('filename')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-text-field
                    class="px-2"
                    v-model="editedItem.subject"
                    :label="$t('subject')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-textarea
                    class="px-2"
                    v-model="editedItem.note"
                    counter
                    auto-grow
                    row-height="5"
                    :label="$t('note')"
                    :rules="rules"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
                  <v-autocomplete
                    class="px-2"
                    :items="getLookup('DocType')"
                    item-text="text"
                    item-value="value"
                    :label="$t('Document Type')"
                    v-model="editedItem.doc_type"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
                  <v-text-field
                    class="px-2"
                    v-model="editedItem.sortKey"
                    dense
                    :label="$t('sortkey')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="px-2"
                        v-model="editedItem.date"
                        :label="$t('Document Date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker dark :max="nowDate" v-model="editedItem.date">
                      <v-checkbox
                        class="justify-center text-center ma-0 mx-auto pa-0"
                        dense
                        v-model="editedItem.isNoDate"
                        :label="
                          `${editedItem.isNoDate ? $t('no') : ''}` +
                          ' ' +
                          $t('dated')
                        "
                      ></v-checkbox>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
                  <v-autocomplete
                    class="px-2"
                    :items="getLookup('DocClass')"
                    item-text="text"
                    item-value="value"
                    :label="$t('Classification')"
                    v-model="editedItem.classification"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
                  <v-autocomplete
                    class="px-2"
                    :items="getLookup('Provider')"
                    item-text="text"
                    item-value="value"
                    :label="$t('provider')"
                    v-model="editedItem.provider"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6">
                  <v-text-field
                    class="px-2"
                    dense
                    v-model="editedItem.docFromName"
                    :label="$t('from')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6">
                  <v-text-field
                    class="px-2"
                    dense
                    v-model="editedItem.docToName"
                    :label="$t('to')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6">
                  <v-text-field
                    class="px-2"
                    dense
                    v-model="editedItem.docFromOrg"
                    :label="$t('fromorganisation')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6">
                  <v-text-field
                    class="px-2"
                    dense
                    v-model="editedItem.docToOrg"
                    :label="$t('toorganisation')"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-text-field
                    class="px-2"
                    dense
                    v-model="editedItem.searchWords"
                    :label="$t('searchwords')"
                  ></v-text-field>
                </v-col>
                <v-col
                  class="ma-0 pa-0"
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="request && request.addresses"
                >
                  <v-text-field
                    class="px-2"
                    :label="`${request.addresses.AddrNum} ${request.addresses.Address}`"
                    readonly
                    filled
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
                  <v-autocomplete
                    class="px-2"
                    :items="getLookup('Translate')"
                    item-text="text"
                    item-value="value"
                    :label="$t('translation')"
                    v-model="editedItem.translation"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
                  <v-text-field
                    class="px-2"
                    v-model="editedItem.pageCount"
                    type="number"
                    min="0"
                    :label="$t('Pages')"
                    flat
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
                  <span class="ma-0 pa-0 px-2 text-left">{{
                    $t("documentlanguage")
                  }}</span>
                  <v-radio-group class="px-2" v-model="editedItem.LangPref">
                    <v-radio dense label="English" value="en"></v-radio>
                    <v-radio dense label="French" value="fr"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
                  <v-text-field
                    class="px-2 ma-0 pa-0"
                    v-model="getItemStatus(editedItem.status)[0]"
                    :label="$t('documentstatus')"
                    readonly
                    filled
                  ></v-text-field>
                  <v-checkbox
                    class="px-2 ma-0 pa-0"
                    v-model="editedItem.is_redact_finished"
                    :label="$t('redationfinished')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close">{{
              $t("cancel")
            }}</v-btn>
            <v-btn class="custom-color-accent" text @click="save">{{
              $t("Index & Save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table :mobile-breakpoint="1081" 
        :headers="headers"
        :items="documents"
        sort-by="sortKey"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
        :search="search"
        expanded.sync
        show-expand
        item-key="id"
        :items-per-page="5"
        :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
      >
        <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>

        <template class="pa-0 ma-0" v-slot:[`item.status`]="{ item }">
          <v-chip
            class="my-1 status-chip justify-center font-weight-bold"
            dark
            style="max-height: 4vh"
          >
            <span class="pa-0 ma-2 font-weight-bold">{{
              getItemStatus(item.status)[0]
            }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ formatDate(item.date) }}</span>
        </template>
        <template v-slot:[`item.provider`]="{item}">
          {{ getLookup('Provider').filter(j=>j.value === item.provider).map(k=>k.text)[0] }}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Read')">
                    <v-icon
                      @click="getOriginalDoc(item)">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("download")}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Edit')">
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Delete')">
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }" elevation="0">
          <td class=" my-side-border" :colspan="headers.length" elevation="0">
            <span class="font-weight-bold text--secondary">
              {{ $t("note") }}: </span
            ><span class="text--secondary">{{ item.note }}</span>
          </td>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
  props: ["request","documents"],
  beforeCreate() {
    this.$store.dispatch("configuration/retriveConfigurations");
  },
  data() {
    return {
      dialog: false,
      nowDate: new moment().toISOString(),
      search: "",
      classes: ["Protected", "Secret", "Top Secret"],
      rules: [
        (value) => (value && value.length <= 200) || this.$t('maxcharacters200'),
      ],
      editedItem: {
        request_id: this.request.id,
      },
      defaultItem: {
        request_id: this.request.id,
      },
      file: null,
      error: "",
      errored: false,
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: this.$t("sortkey"),
          value: "sortKey",
        },

        {
          text: this.$t("filename"),
          value: "name",
        },
        {
          text: this.$t("date"),
          value: "date",
        },
        {
          text: this.$t("numberofpages"),
          value: "pageCount",
        },
        {
          text: this.$t("provider"),
          value: "provider",
        },
        { text: "", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("editdocument")
        : this.$t("editdocument");
    },
    tableTitle() {
      return this.$t("registreddocuments");
    },
    ...mapState("configuration", {
      configurations: "configurations",
    })
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.documents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    getItemStatus(status) {
      return this.configurations
        .filter(
          (filter) =>
            filter.LookUpName === "DocStatus" && filter.LookUpKey === status
        )
        .map((item) => {
          return this.$vuetify.lang.current === "en"
            ? item.LookUpStringE
            : item.LookUpStringF;
        });
    },
    getLookup(column) {
      return this.configurations
        .filter((filter) => filter.LookUpName === column)
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    async deleteItem(item) {
      const index = this.documents.indexOf(item);
      if (
          confirm(this.$t("Are you sure you want to delete this item?")) &&
        this.documents.splice(index, 1)
      ) {
        this.deleteDocument(item.id);
        this.$emit("done");
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    getOriginalDoc(doc) {
      return new Promise((resolve, reject) => {
        axcessApi
          .getRequest(
            "/originaldoc/" + this.request.id + "/docid/" + doc.name,
            { responseType: "arraybuffer" }
          )
          .then((res) => {
            let blob = new Blob([res.data], { type: "application/*" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = doc.name;
            link._target = "blank";
            link.click();
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async save() {
      this.editedItem.status = "R";
      this.editedItem.statusDate = moment().format("YYYY-MM-DD");
      this.updateDocument(this.editedItem);
      this.$emit("done");
      this.close();
    },

    formatDate(value) {
      return value ? moment(value).format("YYYY-MM-DD"):'';
    },

    ...mapActions("documents", ["deleteDocument", "updateDocument"]),
  },
};
</script>
<style>
.status-chip {
  width: 150px;
}
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>