<template>
  <div>
    <div>
        <v-row class="">
          <v-col
            v-for="file in this.filesprop"
            :key="file.name"
            class="pa-3 ma-0"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-input
              class="customlightgray my-border ma-0 pa-3"
              :messages="bytesToSize(file.size)"
              prepend-icon="mdi-file"
            >
              <v-list-item-subtitle>{{file.name}}</v-list-item-subtitle>
              <v-icon class="ml-auto clickable" @click="removeFile(file)"
                >mdi-close</v-icon
              >
            </v-input>
          </v-col>
        </v-row>
    </div>
    <div v-if="!file" class="customlightgray">
      <div
        @dragover="dragging = true"
        @dragleave="dragging = false"
        :class="['dropZone my-border', dragging ? 'dropZone-over' : '']"
      >
        <div class="dropZone-info" @drag="onChange">
          <v-icon class="dropZone-title">mdi-cloud-upload</v-icon><br />
          <span class="dropZone-title">{{$t("drop")}}</span>
          <div class="dropZone-upload-limit-info">
            <div>{{$t("max")}} {{ bytesToSize(this.allowedSize) }}</div>
          </div>
        </div>
        <input type="file"  :accept="allowedTypes" @change="onChange" :multiple="multiple" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ["allowedTypes", "allowedSize","filesprop","multiple"],
  components: {
  },
  data: () => ({
    file: "",
    files: [],
    dragging: false,
  }),
  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files);
    },
    createFile(files) {
      files;
      this.files=[];
      Object.assign(this.files, this.filesprop);
      var i;
      if(!this.multiple && this.files.length>0) {
          this.dragging = false;
          this.$root.$emit("callAlert", {
            text: this.$t("multiAlert"),
            type: "warning",
            alert: true
          });
          return
        }
      for (i = 0; i < files.length; i++) {
        if (!this.allowedTypes.includes(files[i].type)) {
          this.$root.$emit("callAlert", {
            text: this.$t("compatibleAlert"),
            type: "error",
            alert: true
          });
          this.dragging = false;
        } else if (files[i].size > this.allowedSize) {
           this.$root.$emit("callAlert", {
            text: this.$t("sizeAlert"),
            type: "error",
            alert: true
          });
          this.dragging = false;
        } else if (this.filesprop.some((el) => el.name === files[i].name)) {
          this.$root.$emit("callAlert", {
            text: this.$t("existsAlert"),
            type: "warning",
            alert: true
          });
          this.dragging = false;
        } else {
          this.files.push(files[i]);
          this.$root.$emit("callAlert", {
            text: this.$t("fileAddedAlert"),
            type: "success",
            alert: true
          });
          this.dragging = false;
        }
      }
      this.$emit("upload-file", this.files);
    },
    removeFile(item) {
      this.file = "";
      var index = this.files.indexOf(item);
      if (index !== -1) {
        this.files.splice(index, 1);
        this.$root.$emit("callAlert", {
            text: this.$t("fileRemovedAlert"),
            type: "dark",
            alert: true
          });
        this.$emit("upload-file", this.files);
        this.dragging = false;
      }
    },
    hide_alert() {
      this.alert = false;
    },
    getExtension(filename) {
      return filename.split(".").pop();
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
  mounted: function () {
    if (alert) {
      this.hide_alert();
    }
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>

<style scoped>
.dropZone {
  width: 100%;
  height: 150px;
  position: relative;
}

.dropZone:hover {
  border: 1px solid #14535c !important;
}

.dropZone:hover .dropZone-title {
  color: #14535c;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #16464348 !important;
  opacity: 0.8;
}

.removeFile {
  width: 200px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: -1;
}
</style>